import React from 'react';
import {Button, Layout, Row, Col} from "antd";
import {Content} from "antd/es/layout/layout";
import './style.scss'
import image1 from '../img/Group 1994 (1).png'
import tutorial from './tutorial.mov'
import compad_industry_sector from './compad_industry_sector.jpg'
import compad_playing_time from './compad_playing_time.jpg'


const txt = [
    'This Knowledge Transfer Partnership (KTP) with NTU aims to create the Vieunite Integrated Therapeutic Digital Display System, incorporating sensor-integrated displays and an AI-powered content engine. The project explores how positive (salutogenetic) and biophilic (nature-inspired) theories can be utilised in digital display systems to enhance the health benefits of connecting people with digital nature representations. These sophisticated displays will offer therapeutic content influenced by biophilic theory, individual circadian rhythms, mood variations, and indoor environmental conditions. The primary focus is to boost the health and well-being of occupants by generating personalised salutogenetic biophilic digital content through precise information exchange between customized control and analytics algorithms.',
    'This KTP will also aid Allsee in developing Vieunite, a new digital platform for artists to showcase their work directly to potential clients by aligning the biophilic attributes of the art with the users\' biophilic preferences. Vieunite will make biophilic art easily accessible to the public, promoting overall health and well-being.',
    'The innovation leverages recent research in biophilic theory and smart building technologies. The importance of digital displays in smart, healthy buildings is often overlooked, and this KTP project aims to fill this gap by developing comprehensive Digital Therapeutic Display Systems that integrate hardware, software, and content seamlessly.',
    'The first research paper, titled: \'Exploring Machine Learning Applications for Biophilic Art Displays to Promote Health and Well-being\' , Link was presented at the PETRA’24 (PErvasive Technologies Related to Assistive Environments) conference. It lays out the framework for our study, where we developed a novel algorithm that classifies and recommends biophilic artwork based on emotional responses of users. Our initial feedback indicates a positive reception thus paving the way for further research in using nature-inspired art as a therapeutic prescription to alleviate negative emotions and promote positivity.  Our second research paper titled \'A Deep Learning Method for Classification of Biophilic Artworks\', Link focusses on using state-of-the-art artificial intelligence algorithms for categorising artworks based on biophilic features and curating a diverse collection of Biophilic artworks. Our techniques have the potential to help artists, curators, and researchers studying to interpret and exploit the effects of mental well-being on exposure to nature-inspired visual aesthetics but also enables a methodical exploration of the study of Biophilia and Biophilic artwork for aesthetic preferences.',
    'Currently we are exploiting Machine Learning Techniques to build a system capable of recognizing emotions from artworks. Such a system would help discern emotions associated with art and can not only offer deeper cognizance for artists and researchers but also help in creating well-curated collections. Our work also presents potential applications in building advanced recommendation systems using emotional elements.'
]
const Compad = (props) => {
    return (
        <Content style={{
            margin: '0 0 0 0',
            background: '#F0F0F0', padding: '100px 0'
        }}>
            <p className={'research-demo-p1'}>
                COMPaD: Commercial-Oriented Multi-modal Poster Generation and Design
            </p>
            <p className={'research-demo-p2'}>
                revolutionises commercial poster creation by integrating advanced AI technologies
            </p>
            <img className={'research-demo-img'} alt={'bio'} src={image1}/>
            <p className={'research-demo-p3'}>
                <b>COMPaD: Commercial-Oriented Multi-modal Poster Generation and Design</b> is an innovative project
                aimed at transforming the traditional commercial poster design landscape through the power of artificial
                intelligence. The project addresses the challenges posed by conventional design methods, which often
                involve lengthy and inefficient iterations between clients and designers. COMPaD introduces a
                cutting-edge AI-powered platform that streamlines the design process, allowing users to rapidly
                generate, edit, and customize posters to fit specific commercial needs across multiple industries such
                as Retail, Food and Drink, Corporate, and Healthcare.
            </p>
            <p className={'research-demo-p3'}>
                The platform leverages a comprehensive dataset of over 150,000 commercial posters, which is continually
                refined through user feedback and annotations. This dataset forms the backbone of the AI models,
                enabling precise manipulation and generation of posters that align with the intended commercial message
                and aesthetic preferences.
            </p>

            <p className={'research-demo-p3'}>
                Key features of the platform include:
            </p>
            <li className={'research-demo-p3'}>
                <b>Interactive Poster Editing:</b> Users can interact with the system via a chatbot, making specific
                adjustments through natural language commands or speech, enhancing user accessibility and convenience.
            </li>
            <li className={'research-demo-p3'}>
                <b>Style Transfer:</b> The platform can seamlessly integrate styles from different artworks into the
                commercial posters, ensuring visually coherent transitions especially useful in digital and gallery
                settings.
            </li>
            <li className={'research-demo-p3'}>
                <b>Multi-modal Content Generation:</b> Beyond visual elements, COMPaD supports the creation of
                accompanying audio tracks, enhancing the overall impact and engagement of the digital posters.
            </li>

            <p className={'research-demo-p3'}>
                The project not only aims to refine the technical capabilities of AI in poster design but also seeks to
                tap into a new market, providing significant commercial benefits and setting the stage for further
                innovations in the field. By simplifying and enhancing the poster design process, COMPaD offers a potent
                tool for brands looking to make a more substantial impact with their advertising efforts.
            </p>
            <h2 className={'research-demo-p2'}>Research Demo</h2>
            <Row justify="center" style={{marginTop: '2rem'}}>
                <iframe src={'https://vieunite.com/digital_poster_demo/'} style={{
                    width: '80vw',
                    height: '80vh',
                    textAlign: "center",
                    margin: "auto",
                    border: "none"
                }}/>
            </Row>
            <h2 className={'research-demo-p2'}>Poster Editing Tool</h2>
            <Row justify={"center"} style={{width: '80vw', margin: '2vh auto'}}>
                <video src={tutorial} style={{width: '80vw', margin: "auto"}} controls={true}/>
            </Row>
            <p className={'research-demo-p3'}>
                The poster editing tool is hosted on Amazon Mechanical Turk (AMT). To access the tool, please click <a
                href={'https://workersandbox.mturk.com/projects/3R35RPFD8VCRW6XOW48HJFGNCWWP16/tasks?ref=w_pl_prvw'}>here</a>.
            </p>
            <h2 className={'research-demo-p2'}> Analysis</h2>
            <Row justify={"center"} style={{width: '80vw', margin: '2vh auto'}}>
                <Col>
                    <img src={compad_industry_sector} style={{height: '16vw', margin: "auto"}}/>
                    <p style={{width: '30vw', margin: "auto", fontSize: "0.75rem"}}>Analysis of poster and creator
                        distribution across different industry sectors. a) Creators over different industry sectors. b)
                        Poster numbers over different industry sectors.</p>
                </Col>
                <Col>
                    <img src={compad_playing_time} style={{height: '16vw', margin: "auto"}}/>
                    <p style={{width: '30vw', margin: "auto", fontSize: "0.75rem"}}>Analysis of posters based on playing
                        time and region. a) Poster numbers based on playing time on screens. b) Number of posters
                        playing in different regions, “Unknown” indicates that geolocations of posters are unavailable
                        or user opt-out data analysis.</p>
                </Col>
            </Row>


            <div className={'research-demo-final-div'}>
                <p className={'research-demo-final-div-p1'}>Authors</p>
                <p className={'research-demo-final-div-p2'}>Chenyuan Qu, Jianbo Jiao</p>
                <p className={'research-demo-final-div-p1'} style={{marginTop: '6vh'}}>Acknowledgements</p>
                <p className={'research-demo-final-div-p2'}>
                    This research is supported by Royal Society Short Industry Fellowship (SIF\R1\231009).
                </p>
                {/*<p className={'research-demo-final-div-p1'}>Footnotes</p>*/}
            </div>


        </Content>
    )
}

export default Compad;
